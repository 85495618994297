import React from "react"

import Layout from "../layout"
import { Section, Headline, Paragraph } from "../uikit"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} title="404: Not found">
      <Section>
        <Headline>NOT FOUND</Headline>
        <Paragraph>
          You just hit a route that doesn&#39;t exist... the sadness.
        </Paragraph>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
